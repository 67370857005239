import React, { FC } from 'react';
import AppleStoreLogo from 'assets/icons/apple-store-logo.svg';
import styled from 'styled-components';
import { StoresLinks } from 'utils/constants/externalLinks';

interface AppleStoreLinkBtnProps {
  linkOverride?: string;
}

const StyledLink = styled.a``;
const AppleStoreBadge = styled(AppleStoreLogo)`
  width: 10.21875rem;
  height: 2.95206rem;
  :hover,
  :active,
  :focus {
    opacity: 1;
  }
  cursor: pointer;
`;
const AppleStoreLinkBtn: FC<AppleStoreLinkBtnProps> = ({
  linkOverride,
  ...props
}) => (
  // <StyledLink href={} target="_blank" {...props}>
  <AppleStoreBadge
    onClick={() =>
      window.open(linkOverride ?? StoresLinks.AppleStore, '_system')
    }
  />
  // </StyledLink>
);

export default AppleStoreLinkBtn;
