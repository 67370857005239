import { Text } from 'components';
import AppleStoreButton from 'pages/start/success/components/AppleStoreButton';
import GooglePlayButton from 'pages/start/success/components/GooglePlayButton';
import React, { FC } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';
import Lottie from 'lottie-react';
import success from 'assets/lotties/success.json';
import { AppState } from 'state/types';
import { useSelector } from 'react-redux';

interface RegisterCompletedProps {
  successTitle?: string;
  successInfo?: string;
  appleStoreLinkOverride?: string;
  googlePlayLinkOverride?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem auto 3rem;
  max-width: 28.75rem;
  width: 100%;
  @media ${tablet} {
    margin: 2.25rem auto 3rem;
    padding: 0 1rem;
  }
`;

const Title = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  padding-bottom: 0.75rem;
  @media ${tablet} {
    font-size: 1.5rem;
  }
`;

const Label = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding-bottom: 2.5rem;
  span {
    font-weight: 600;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 1rem;
`;

const SvgContainer = styled.div`
  width: 8.75rem;
  height: 8.75rem;
  margin-bottom: 1.5rem;
  svg {
    width: 8.75rem;
    height: 8.75rem;
  }
`;

const AppleStoreButtonStyled = styled(AppleStoreButton)`
  -webkit-tap-highlight-color: transparent;
`;
const GooglePlayButtonStyled = styled(GooglePlayButton)`
  -webkit-tap-highlight-color: transparent;
`;
const RegisterCompleted: FC<RegisterCompletedProps> = ({
  successTitle,
  successInfo,
  appleStoreLinkOverride,
  googlePlayLinkOverride,
}) => {
  const { user } = useSelector((state: AppState) => state.user);
  return (
    <Container>
      <SvgContainer>
        <Lottie animationData={success} loop={false} />
      </SvgContainer>
      <Title>
        {successTitle
          ? successTitle
          : 'You have successfully created your account!'}
      </Title>
      <Label
        dangerouslySetInnerHTML={{
          __html: successInfo
            ? successInfo.replace('${email}', user?.email)
            : 'Download the Carnimeat app on your phone using the buttons below! Use your email <span>${email}</span> and created password to log in.'.replace(
                '${email}',
                user?.email,
              ),
        }}
      />

      <ButtonContainer>
        <AppleStoreButtonStyled linkOverride={appleStoreLinkOverride} />
        <GooglePlayButtonStyled linkOverride={googlePlayLinkOverride} />
      </ButtonContainer>
    </Container>
  );
};

export default RegisterCompleted;
